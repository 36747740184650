


















































import { Component, Vue } from "vue-property-decorator";
import UserService from "@/api/user";
import http from "@/api/http";

@Component
export default class Login extends Vue {
  user: any = {
    email: "",
    password: ""
  };
  hasEmptyValues = false;

  login(): void {
    this.validateData();
    UserService.login(this.user)
      .then(response => {
        const jwtValid = this.setUserData(response.data.token);
        if (!jwtValid) {
          this.$buefy.notification.open({
            message: "Ha ocurrido un error al iniciar sesión",
            type: "is-danger"
          });
          return;
        }
        this.$router.push({
          name: "Home"
        });
      })
      .catch(() => {
        this.$buefy.notification.open({
          message: "Ha ocurrido un error al iniciar sesión",
          type: "is-danger"
        });
      });
  }

  setUserData(token: string): boolean {
    const decoded = JSON.parse(atob(token.split(".")[1]));
    if (decoded.user) {
      const user = JSON.parse(atob(decoded.user));
      localStorage.setItem("tk", token);
      http.defaults.headers.common = {
        Authorization: `${token}`
      };
      this.$store.dispatch("UserModule/setMe", user);
      return true;
    }
    return false;
  }

  validateData(): void {
    this.hasEmptyValues = false;
    Object.values(this.user).forEach(value => {
      if (value == "") {
        this.hasEmptyValues = true;
      }
    });
    if (this.hasEmptyValues) {
      this.$buefy.notification.open({
        message: "Los campos no pueden estar vacios",
        type: "is-danger"
      });
      return;
    }
  }

  focusNextSibling(ref: string): void {
    ((this.$refs[ref] as Vue).$el.firstChild as HTMLElement).focus();
  }

  created(): void {
    this.user.email = this.$route.params.email;
  }
}
